@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
  font-family: "bariolRegular";
  src: url(/src/fonts/Bariol_Regular.otf);
}

@font-face {
  font-family: "bariolMedium";
  src: url(/src/fonts/Barlow-Medium.ttf);
}

@font-face {
  font-family: "bariolBold";
  src: url(/src/fonts/Bariol_Bold.otf);
}

@layer base {
  h2{
    @apply font-[bariolRegular] text-center 
    text-[24px] leading-[24px]
    md:text-[30px] lg:text-[32px] sm:leading-[30px] lg:leading-[32px]
  }
}
@layer components {
  .p1{
    @apply font-[bariolRegular] text-center text-white font-normal
    text-[20px] sm:text-[24px] lg:text-[28px] 
    leading-[22px] sm:leading-[26px] lg:leading-[30px] 
  }
  .p2{
    @apply font-[bariolRegular] 
    text-[14px] leading-[16px] sm:text-[18px] sm:leading-[20px]
  }
  .textNav{
    @apply font-[bariolBold] !cursor-pointer
    text-center text-[20px] sm:text-[18px] text-white
  }
  .textNavMb{
    @apply font-[bariolBold] !cursor-pointer w-full
    text-center text-[20px] text-white py-[30px] hover:bg-gray-700
  }
  .pySection{
    @apply py-[32px] sm:py-[48px]
  }
  .textCuenta{
    @apply font-[bariolRegular] text-center 
    text-[20px] leading-[22px] sm:text-[24px] sm:leading-[28px]
  }
}

@layer utilities {
  .container{
    @apply w-[92%] mx-auto
    max-w-[1110px] xl:w-full
  }

}